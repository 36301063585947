body{
    padding:0px;
    margin:0px;
    border:0px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
/*SPLASH PAGE
/////////////////////////////////////////////////////////*/
#splashpage{ 
    height:100vh;
    width:100vw;
    text-align:center;
    background:white;   
    margin-bottom:5%;   /*to ensure About Page doesn't collide with slides*/
    /* background-image: url("./images/splash4.png"); */
    background-repeat: no-repeat; 
    background-size: cover;
    overflow:hidden;
}
#Img1{
    height:35%;
    padding:5%;
    padding-top:20%;
    padding-left:15%;
    text-align:left;
}
#Img2{
    display:none;
    margin-top:10%;
    text-align:right;
    height:150px;
}
#Img3{    
    padding:0;
    padding-top:15%;
    margin-left:10%;
    text-align:center;
}
/* #sb{    
    padding:20%;
    padding-top:70%;
    text-align:right;
} */
.snowAnim {
    display: flex;
    justify-content: center;
    align-items: center;
} 
/* .snow {
    width: 3.5em;
    height: 3.5em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
}
.snow1 {
    width: 1.5em;
    height: 1.5em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
}
.snow2 {
    width: 3.5em;
    height: 3.5em;
    border:3px solid black;
    border-radius: 50%;
}
.snow3 {
    width: 3em;
    height: 3em;
    padding-top:20%;
    border:3px solid black;
    border-radius: 50%;
}
.snow4 {
    width: 1em;
    height: 1em;
    border:3px solid black;
    border-radius: 50%;
}
.snow5 {
    width: 2em;
    height: 2em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
} */
.snow {
    width: 3.5em;
    height: 3.5em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
}

/*#1fromLeft*/
.snow2 {
    width: 1em;
    height: 1em;
    border:3px solid grey;
    border-radius: 50%;
}
/*#2fromLeft*/
.snow5 {
    width: 2em;
    height: 2em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
}
/*#3fromLeft*/
.snow4 {
    width: 1em;
    height: 1em;
    border:3px solid black;
    border-radius: 50%;
}
/*#4fromLeft*/
.snow1 {
    width: 1em;
    height: 1em;
    padding-top:15%;
    border:3px solid black;
    border-radius: 50%;
}
/*#5fromLeft*/
.snow3 {
    width: 2em;
    height: 2em;
    padding-top:20%;
    border:3px solid grey;
    border-radius: 50%;
}


.snowfalling {
    position: absolute;
    -webkit-animation: fall 10s linear infinite; 
            animation: fall 10s linear infinite;
}
.snowfalling1 {
    position: absolute;
    right:25%;
    top:60%;
    -webkit-animation: fall 10s linear infinite; 
            animation: fall 10s linear infinite;
}
.snowfalling2 {
    position: absolute;
    left:10%;
    top:30%;
    -webkit-animation: fall 12s linear infinite; 
            animation: fall 12s linear infinite;
}
.snowfalling3 {
    position: absolute;
    right:10%;
    top:43%;
    -webkit-animation: fall 10s linear infinite; 
            animation: fall 10s linear infinite;
}
.snowfalling4 {
    position: absolute;
    left:40%;
    top:30%;
    -webkit-animation: fall 7s linear infinite; 
            animation: fall 7s linear infinite;
}
.snowfalling5 {
    position: absolute;
    left:25%;
    top:70%;
    -webkit-animation: fall 11s linear infinite; 
            animation: fall 11s linear infinite;
}
.delay1 {
    -webkit-animation-delay: 1s; 
      animation-delay: 1s;
}
.delay2 {
    -webkit-animation-delay: 2s; 
      animation-delay: 2s;
}
  .delay3 {
    -webkit-animation-delay: 5s; 
      animation-delay: 5s;
  }
  @-webkit-keyframes fall {	
    0% { opacity:0; transform: translateY(-15vw); }
    20% { opacity:1; transform: translateY(-9vw); }	
    80% { opacity:1; transform: translateY(9vw); }	
    100% { opacity:0; transform: translateY(15vw); }	
  }
  @keyframes fall {
      0% { opacity:0; transform: translateY(-15vw); }	
     20% { opacity:1; transform: translateY(-9vw); }	
     80% { opacity:1; transform: translateY(9vw); }	
    100% { opacity:0; transform: translateY(15vw); }	
  }


/*UNIVERSAL STYLING
/////////////////////////////////////////////////////////*/
.sections{  /*wrapper around all the component contents */
    margin-top:5%;
    padding-top:3%;
    margin-bottom:10%;
    text-align:center;
}
.pageTitle{ 
    display:block;
    width:100%;
    margin-top:5%;
    margin-bottom:40px;
    text-align: center;
    color:grey;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 25px;
}
/*NAVIGATION
/////////////////////////////////////////////////////////*/
.scrolled{  /*ensures the navigation bar stays fixed upon scrolling*/
    position:fixed;
    top:0px;
    z-index:5;
}
.nav{ /*Navigation bar*/
    width:100vw;
    transition:0.25s;
    z-index:5;
    background:white;
    text-align: center;
    height: 60px;
    box-shadow: 0 0 5px grey;
}
.nav_text{ /*div wrapped around navigation links*/
    width:100%;
    transition:0.25s;
}
.NavSnsImg{
    height:60px;
    margin-bottom:10px;
    margin-right:20px;
    padding:10px;
    transition: transform 0.5s; /* Animation */
}
.NavSnsImg:hover{
    transform: scale(1.2);
    opacity:0.6;
}
.navLinks{
    height:60px;
    margin-bottom:10px;
    margin-right:20px;
    padding:10px;
    transition: transform 0.5s; /* Animation */
}
.navLinks:hover{
    transform: scale(1.2);
    opacity:0.6;
}
#logo{ /*logo on navigation bar*/
    height:65px;
    position:relative;
    margin-right:25%;
    margin-bottom:10px;
    padding-bottom:10px;
    padding-top:10px;
    transition: transform 0.5s; /* Animation */
}
#logo:hover{
    transform: scale(1.2);
    opacity:0.6;
}
/*ABOUT & Skills PAGE
/////////////////////////////////////////////////////////*/
#about{
    padding:10%;
    background:black;
    width:100vw;
}
.aboutinfo{
    color:darkgrey;
    text-align:justify;
}
.info{
    margin-top:10%;
}
.learn{
    font-size:14px;
    color:darkgrey;
}

/*ORDERS
/////////////////////////////////////////////////////////*/
#instructions{
    text-align:center;
    margin:0;
    margin-bottom:5%;
    border:0;
    padding:20px;
    width:100vw;
}
.instructSub{
    margin:0;
    border:0;
    padding:0;
    color:grey;
    font-size:12px;
}
/*PROJECTS
/////////////////////////////////////////////////////////*/
#projects{
    float:none;
    margin:0;
    padding:15px;
    border:0;
}
.ProjWrapper{
    float:left;
    margin:2.5%;
    box-shadow:  0 0 5px  black;
    overflow:hidden;
}
.ProjWrapper a{
    color:black;
}
.icons{
    height:40px;
    padding:5px;
    transition: transform 1s; /* Animation */
}
.codeLink{
    margin-bottom:3.3%;
    transition: transform 1s; /* Animation */
}
.codeLink:hover{
    opacity:0.6;
    transform: scale(1.1);
}
.projectContainer{
    text-align:center;
    height:265px;
    width:400px;
    overflow:hidden;
    box-shadow:  0 0 5px  black;
    transition: transform 2s; /* Animation */
}
.projectContainer:hover{
    transform: scale(1.1);
}
.topDiv {
    width: 100%;
    height: 100%;
    padding:0;
    box-shadow:  0 0 5px  grey;
    background:black;
    opacity:0.6;
    transition: transform 5s; /* Animation */
}
.topDiv:hover{
    opacity:0;
    transform: scale(1.2);
}
.divUnder{ /*the div under the img, which will show when img turns opague (on:hover). Wraps around proudctName, cakeDescription & allergy*/
    position:relative;
    top:-100%;
    padding:0;
    margin:0;
    border:0;
    font-family: serif;
    font-size: 16px;
    z-index:-5;
}
.divUnder img{
    height:100%;
}
.divUnder:hover{
    z-index:5;
    opacity:1
}
.projImg{
    width: 100%;
    height: 100%;
    padding:0;
    margin:0;
}
.projLink{
    background-image:url("./images/projScreenshot/Design1.PNG");
    background-repeat: no-repeat; 
    background-size: cover;
    overflow:hidden;
}
.projDescription{ /*individual, whole & poundcakes: div wrapped around ingredients*/
    float:right;
    text-align:right;
    color:black;
    font-family: sans-serif;
    font-size: 12px;
}
.cakeDescription p{ /*individual, whole & poundcakes: the ingredients*/
    margin:0;
    padding:0;
}
.techIcon{ /*individual, whole & poundcakes: container of allergent icon*/
    position:relative;
    margin:0;
    margin-top:-5%;
    border:0;
    padding:0;
    /* padding-top:10%; */
}
.techIcons{ /*individual, whole & poundcakes:allerget icons*/
    /* height:50px; */
    border:0;
    padding:2px;
    padding-top:12px;
    margin:0;
}
/*BLOG 
/////////////////////////////////////////////////////////*/


/*FOOTER
/////////////////////////////////////////////////////////*/
#footer{
    float:left;
    bottom:0;
    margin-top:20%;
    margin-bottom:10px;
    z-index: 1;
    width:100vw;
    padding:0;
    border:0;
    border-top:solid 1px rgba(0,0,0,0.4);
    box-shadow: 0 0 5px #F9F0EC;
    background:black;
}
#footer a{
    color:grey;
}
#snsContainer{
    text-align:center;
    margin-bottom:5%;
}
#copyright{ 
    padding:10px;
    font-size:14px;
}
.footerText{
    display:block;
    width:100%;
    margin-top:1%;
    margin-bottom:1%;
    text-align: center;
    color:grey;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 25px;
}
.snsImg{
    height:80px;
    width:80px;
    padding:10px;
    transition: transform 0.5s; /* Animation */
}
.snsImg:hover{
    opacity:0.6;
    transform: scale(1.2);
}

/*MEDIA RESPONSIVENESS 
  IPAD PRO  = 1024 x 1366
  IPAD      =  768 X 1024
  IPHONE X  =  375 X  812
  IPHONE SE =  320 X  568
  GALAXY S5 =  360 X  640
  PIXEL     =  411 X  731
  PIXEL XL  =  411 X  823
/////////////////////////////////////////////////////////*/

@media only screen and (max-width: 1024px) { /*  IPAD PRO landscape*/
    *{
        overflow:auto;
    }
    #name{
        padding:20%;
        padding-bottom:0;
        text-align:center;
        height:auto;
        margin-bottom:0;
    
    }
    #domain{    
        padding:0;
        padding-top:0;
        text-align:center;
        margin-bottom:10%;
    }
    #sb{    
        padding-top:0;
        padding:20%;
        text-align:center;
    }
}
@media only screen and (max-width: 770px) { /* IPAD PORTRAITS & MOBILES*/
    *{
        overflow:auto;
    }
    #Img1{
        display:none;
    }
    #Img2{
        display: inline-flex;
        margin-left: 25%;
        padding: 0%;
        /* text-align: center; */
        height: 18%;
    }

    #Img3{    
        padding:0;
        padding-top:10%;
        padding-left:10%;
        margin-left:0%;
        text-align:center;
    }
    .nav_text{
        text-align:center;
        padding:2px;
    }

    #logo{
        display:none;
    }
    .NavSnsImg{
        margin-right:0;
    }
    .snowfalling {
        position: absolute;

    }
    .snowfalling1 {
        position: absolute;
        right:35%;
        top:40%;
        -webkit-animation: fall 9s linear infinite; 
                animation: fall 9s linear infinite;
    }
    .snowfalling2 {
        position: absolute;
        left:10%;
        top:0%;
        -webkit-animation: fall 12s linear infinite; 
                animation: fall 12s linear infinite;
    }
    .snowfalling3 {
        position: absolute;
        right:10%;
        top:43%;
        -webkit-animation: fall 10s linear infinite; 
                animation: fall 10s linear infinite;
    }
    .snowfalling4 {
        position: absolute;
        left:10%;
        top: 50%;
        -webkit-animation: fall 7s linear infinite; 
                animation: fall 7s linear infinite;
    }
    .snowfalling5 {
        position: absolute;
        left:25%;
        top:65%;
    }    
}